import React from 'react';
import '../styles/Form.css';

const FormSuccess = () => {
  return (
    <div className='form-content-right'>
      <h1 className='form-success'>Gracias, tu mensaje se envío correctamente!</h1>
  
    </div>
  );
};

export default FormSuccess;